import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import RemoteSchemaService from "../../services/RemoteSchemaService";
import MessageBox from "../../components/message/MessageBox";
import { Alert } from "@material-ui/lab";
import ExtractionStart from "../../components/extraction/ExtractionStart";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

const LoadView: FC = (): ReactElement => {

    const {
        listSchemas,
        loadSchema
    } = RemoteSchemaService();
    
    const { id } = useParams<{ id?: string }>();
    const classes = useStyles();
    const history = useHistory();

    const [connectorId, setConnectorId] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [clientID, setClientID] = useState('');
    const [netSuiteURL, setNetSuiteURL] = useState('');
    const [authMessage, setAuthMessage] = useState('');
    const [extractionStartPhase, setExtractionStartPhase] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [running, setRunning] = useState(false);
    const [schemas, setSchemas] = useState<string[]>();
    const [selectedSchema, setSelectedSchema] = useState<string>();
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    useEffect(() => {
        
        listSchemas(getId()).then(res => 
            {           
                setSchemas(res.data);
            
        })
    }, [])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <form className={classes.root} noValidate autoComplete="off">
                    <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="schema-name-label">Schema</InputLabel>
                            {<Select
                                labelId="schema-name-label"
                                id="schema-name"
                                value={selectedSchema}
                                //disabled={destSchemaSelectDisabled}
                                onChange={(event) => {
                                    setSelectedSchema(event.target.value.toString());
                                }}
                                label="Schema Name"
                            >
                                <MenuItem value={0}>Select</MenuItem>
                                {schemas && schemas.map((s, index) => (
                                    <MenuItem key={index} value={s}>{s}</MenuItem>
                                ))}
                            </Select>}
                        </FormControl>
                    </form>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <div>
                <Button
                    className={classes.button}
                    variant="contained"
                    disabled={waitingForResponse || running}
                    onClick={() => {
                        setWaitingForResponse(true)
                        loadSchema(getId(), selectedSchema).then(res => {
                            if (res.success) {
                                // do something?
                            }else{
                                setAlertMessage({
                                    message: res.error[0],
                                    severity: 'error',
                                    title: 'Error Loading Schema'
                                });
                            }
                        }).finally(() => {
                            setWaitingForResponse(false);
                        });
                    }}>
                    Load
                </Button>
                {waitingForResponse && <CircularProgress style={{ width: 24, height: 24 }} />}
            </div>
            <div>
                {running && 'Loading schema...'}
            </div>

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>
        </div>
    )
}

export default LoadView;