import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ExtractionService from "../../services/ExtractionService";
import MessageBox from "../../components/message/MessageBox";
import { Alert } from "@material-ui/lab";
import ExtractionStart from "../../components/extraction/ExtractionStart";
import CircularProgress from '@material-ui/core/CircularProgress';
import SourceConnectionService from "../../services/SourcesConnectionService";
import { SourceConnection } from "../../models/SourceConnection";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

const ExtractionView: FC = (): ReactElement => {

    const {
        getProgress,
        login,
        start,
        cancel,
        stop
    } = ExtractionService();
    
    const { id } = useParams<{ id?: string }>();
    const classes = useStyles();
    const history = useHistory();

    const [connectorId, setConnectorId] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [clientID, setClientID] = useState('');
    const [netSuiteURL, setNetSuiteURL] = useState('');
    const [authMessage, setAuthMessage] = useState('');
    const [extractionStartPhase, setExtractionStartPhase] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [running, setRunning] = useState(false);
    const [time, setTime] = useState('');
    const [percent, setPercent] = useState(0);
    const { getSourceConnection } = SourceConnectionService();
    const [sourceConnection, setSourceConnection] = useState<SourceConnection>();
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    useEffect(() => {
        if (id) {
            getSourceConnection(getId()).then(connection => {
                setSourceConnection(connection);
            });
        }
    }, []);

    useEffect(() => {
        setWaitingForResponse(true);
        getProgress(sourceConnection?.source.name).then(res => {
            setRunning(res.running);
            setPercent(res.percentComplete);
            setTime(res.timeRemaining);
        }).finally(() => {
            setWaitingForResponse(false);
        });
    }, [])

    return (
        <div>
            <div style={{margin:'10px', fontSize:16}}>
                {!running && 'Log in with an account that has access to the records catalog to begin extraction process. Credentials entered below are not saved.'}
            </div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <form className={classes.root} noValidate autoComplete="off">

                        <TextField 
                            className={classes.fullWidthField} 
                            id="outlined-basic" 
                            label="Email"
                            variant="outlined"
                            value={email}
                            disabled={waitingForResponse || running}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            placeholder="Email" />

                        <TextField 
                            className={classes.fullWidthField} 
                            id="outlined-basic" 
                            label="Password"
                            type={'password'}
                            variant="outlined"
                            value={password}
                            disabled={waitingForResponse || running}
                            onChange={(event) => {
                                setPassword(event.target.value);
                            }}
                            placeholder="Password" />

                    </form>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <div>
                <Button
                    className={classes.button}
                    variant="contained"
                    disabled={waitingForResponse || running}
                    onClick={() => {
                        setWaitingForResponse(true)
                        login({
                            connector: getId(),
                            email: email,
                            password: password
                        }).then(res => {
                            if (res.success) {
                                setClientID(res.clientID);
                                setNetSuiteURL(res.netsuiteURL);
                                setAuthMessage(res.additionalAuthentication)
                                setExtractionStartPhase(true);
                            }else{
                                setAlertMessage({
                                    message: res.error[0],
                                    severity: 'error',
                                    title: 'Error Logging into NetSuite'
                                });
                            }
                        }).finally(() => {
                            setWaitingForResponse(false);
                        });
                    }}>
                    Login
                </Button>
                {waitingForResponse && <CircularProgress style={{ width: 24, height: 24 }} />}
            </div>
            <div style={{textAlign:'center'}}>
                <div style={{fontSize:18, marginBottom:'10px'}}>
                    {!running && 'No extraction currently running'}
                    {running && 'Extraction in Progress:'}
                </div>
                <div>
                    {running && 'Percent complete: ' + percent}
                </div>
                <div>
                    {running && 'Time remaining: ' + time}
                </div>
                <div style={{marginTop:'10px'}}>
                    <Button 
                        className={classes.button}
                        variant="contained" 
                        disabled={!running}
                        onClick={() => {
                            setWaitingForResponse(true)
                            getProgress(sourceConnection?.source.name).then(res => {
                                setRunning(res.running);
                                setTime(res.timeRemaining);
                                setPercent(res.percentComplete);
                            }).finally(() => {
                                setWaitingForResponse(false);
                            });
                        }}>
                        Refresh
                    </Button>
                    <Button 
                        className={classes.button}
                        variant="contained" 
                        disabled={!running}
                        onClick={() => {
                            setWaitingForResponse(true)
                            stop(sourceConnection?.source.name).then(res => {
                                setRunning(false);
                                setPercent(0);
                                setTime('');
                                setAlertMessage({
                                    message: res,
                                    severity: 'success',
                                    title: 'Extraction stopped'
                                });
                            }).finally(() => {
                                setWaitingForResponse(false);
                            });
                        }}>
                        Stop
                    </Button>
                </div>
            </div>

            <ExtractionStart
                onStart={(auth) => {
                    setExtractionStartPhase(false);
                    setWaitingForResponse(true);
                    start(clientID, {auth}, sourceConnection?.source.name).then(res => {
                        if(res.success){
                            setRunning(true);
                            setAlertMessage({
                                message: 'Number of records: ' + res.numberOfRecords,
                                severity: 'success',
                                title: 'Extraction started'
                            });
                        }else{
                            setAlertMessage({
                                message: res.error[0],
                                severity: 'error',
                                title: 'Error starting extraction'
                            });
                        }
                    }).finally(() => {
                        setWaitingForResponse(false);
                });
                }}
                onWindowClose={() => {
                    setExtractionStartPhase(false);
                    setWaitingForResponse(true);
                    cancel(clientID).then(res => {
                        setAlertMessage({
                            message: 'NetSuite session closed',
                            severity: 'error',
                            title: 'Extraction process cancelled'
                        });
                    }).finally(() => {
                        setWaitingForResponse(false);
                    })
                }}
                show={extractionStartPhase}
                netSuiteURL={netSuiteURL}
                authMessage={authMessage}
            >
            </ExtractionStart>

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>
        </div>
    )
}

export default ExtractionView;