
import AppConfig from '../config';
import { Webhook, WebhookTrigger, WebhookTriggerViewModel } from '../models/Webhook';
import ApiClient from './apiClient';

const WebhookService = () => {

    const { get, post } = ApiClient();

    const saveWebhook = (model: Webhook) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/webhook`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const saveTrigger = (model: WebhookTriggerViewModel) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/trigger`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const deleteWebhook = (id: number) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/webhook/delete/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const deleteTrigger = (id: number) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/trigger/delete/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getWebhook = (id: any) => {
        return new Promise<Webhook>((resolve, reject) => {
            get<Webhook>(`${AppConfig.UiApiBasePath}/webhook/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getAllWebhooks = () => {
        return new Promise<Webhook[]>((resolve, reject) => {
            get<Webhook[]>(`${AppConfig.UiApiBasePath}/webhook`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getAllWebhookTriggers = (id: any) => {
        return new Promise<WebhookTrigger[]>((resolve, reject) => {
            get<WebhookTrigger[]>(`${AppConfig.UiApiBasePath}/webhook-trigger/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const sendWebhook = (id: number) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.CamelApiBasePath}/sendwebhook/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        saveWebhook,
        saveTrigger,
        deleteWebhook,
        deleteTrigger,
        getWebhook,
        getAllWebhooks,
        getAllWebhookTriggers,
        sendWebhook
    }
}

export default WebhookService;