import AppConfig from '../config';
import ApiClient from './apiClient';

const StatusService = () => {

    const { get, post, deleteRecords, put } = ApiClient()

    const getRunning = (): Promise<{ runningJob: string, queuedJobs: string[] }> => {
        return new Promise<{ runningJob: string, queuedJobs: string[] }>((resolve, reject) => {
            get<{ runningJob: string, queuedJobs: string[] }>(`${AppConfig.CamelApiBasePath}/status`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return{
        getRunning
    }

}

export default StatusService;