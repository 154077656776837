import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { LogTotalSummary } from "../../models/LogTable";
import JobsServices from "../../services/JobsServices";
import { NavLink } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        width: ''
    },
}));

interface DashboardCounterProps {
    title: string;
    count: number;
    link?: string;
}

const DashboardCounter: FC<DashboardCounterProps> = ({
    title,
    count,
    link
}): ReactElement => {
    const classes = useStyles();

    return (
        <div>
            <div style={{textAlign: 'right'}}>
                <div style={{ fontSize: 12 }}>Today</div>
                <div></div>
            </div>
            <div>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: 18,
                    textTransform: `uppercase`
                }}>{title}</div>
                <div style={{
                    fontSize: 18
                }}>
                    {link ? (<NavLink to={link}>{count}</NavLink>) : (<>{count}</>)}
                </div>
            </div>
        </div>
    )
}

export default DashboardCounter;