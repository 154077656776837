
const AppConfig = {
    UiApiBasePath: window.env.API_BASE_URL,
    CamelApiBasePath: window.env.API_CAMEL_BASE_URL,
    SchedulerApiBasePath: window.env.API_SCHEDULER_BASE_URL,
    AppVersion: window.env.NIRVANA_APP_VERSION,
    Auth0ClientId: window.env.AUTH0_CLIENT_ID,
    Auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    DDClientName: window.env.DD_CLIENT_NAME,
    ApiAudience: window.env.API_AUDIENCE,
    NirvanaRequiredPermissions: process.env.REACT_APP_NIRVANA_PERMISSIONS
}

export default AppConfig;