import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, CircularProgress, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import { SourceConnection } from "../../models/SourceConnection";
import MetaMappingService from "../../services/MetaMappingService";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ClientSchemaService from "../../services/ClientSchemaService";
import RemoteSchemaService from "../../services/RemoteSchemaService";
import SourceConnectionService from "../../services/SourcesConnectionService";
import {ClientSchema} from "../../models/ClientSchema";
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#349CC9',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

const SourcesMetaEditView: FC = (): ReactElement => {

    const { getClientSchemasForConnection } = ClientSchemaService();
    const { listSchemas } = RemoteSchemaService();

    const {
        getMetaMapping,
        saveMetaMapping,
    } = MetaMappingService();

    const { getAllSourceConnections } = SourceConnectionService();

    const { id } = useParams<{ id?: string }>();
    const classes = useStyles();
    const history = useHistory();
    const [mappingName, setMappingName] = useState('');
    const [mappingPrefix, setMappingPrefix] = useState('');
    const [sources, setSources] = useState<SourceConnection[]>();
    const [selectedSource, setSelectedSource] = useState<any>();
    const [selectedSourceDest, setSelectedSourceDest] = useState<any>();
    const [selectedSourceConnectionId, setSelectedSourceConnectionId] = useState<number>(0);
    const [selectedDestinationConnectionId, setSelectedDestinationConnectionId] = useState<number>(0);
    const [selectedSchemaId, setSelectedSchemaId] = useState<number>(0);
    const [selectedDestSchemaName, setSelectedDestSchemaName] = useState('');
    const [clientSchemas, setClientSchemas] = useState<ClientSchema[]>();
    const [destSchemaNames, setDestSchemaNames] = useState([]);
    const [schemaSelectDisabled, setSchemaSelectDisabled] = useState(false);
    const [destSchemaLoading, setDestSchemaLoading] = useState(false);
    const [destSchemaSelectDisabled, setDestSchemaSelectDisabled] = useState(false);
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    useEffect(() => {
        if (id) {
            getMetaMapping(getId()).then(metaMapping => {
                setMappingName(metaMapping.name);
                setMappingPrefix(metaMapping.prefix);
                setSelectedSourceConnectionId(metaMapping.sourceConnectionOrigin.id)
                setSelectedDestinationConnectionId(metaMapping.sourceConnectionDestination.id)
                if (metaMapping.clientSchema != null) {
                    setSelectedSchemaId(metaMapping.clientSchema.id)
                }
                if (metaMapping.destSchemaName != null && metaMapping.destSchemaName.trim() != "") {
                    setSelectedDestSchemaName(metaMapping.destSchemaName)
                }
            })
        }
        getAllSourceConnections().then(res => {
            setSources(res);
        });
    }, [])

    useEffect(() => {
        getClientSchemasForConnection(selectedSourceConnectionId).then(clientSchemas => {
            if (clientSchemas.length) {
                setClientSchemas(clientSchemas);
                setSchemaSelectDisabled(false);
            } else {
                setClientSchemas([]);
                setSchemaSelectDisabled(true);
            }
            
        })
    }, [selectedSourceConnectionId])

    useEffect(() => {
        setDestSchemaSelectDisabled(true);
        if(selectedDestinationConnectionId > 0){
            setDestSchemaLoading(true);
            listSchemas(selectedDestinationConnectionId).then(listSchemasResponse => {
                if (listSchemasResponse.data && listSchemasResponse.data.length) {
                    setDestSchemaNames(listSchemasResponse.data);
                    setDestSchemaSelectDisabled(false);
                } else {
                    setDestSchemaNames([]);
                }            
            }).finally(() => {
                setDestSchemaLoading(false);
            });
        }
    }, [selectedDestinationConnectionId])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <TextField 
                            className={classes.fullWidthField} 
                            id="outlined-basic" 
                            label="Mapping Name"
                            variant="outlined"
                            value={mappingName}
                            onChange={(event) => {
                                setMappingName(event.target.value);
                            }}
                            placeholder="Mapping Name" />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="source-connection-id-label">Source Connection</InputLabel>
                            {<Select
                                labelId="source-connection-id-label"
                                id="source-connection-id"
                                value={selectedSourceConnectionId}
                                onChange={(event) => {
                                    setSelectedSourceConnectionId(Number.parseInt(event.target.value.toString()));
                                }}
                                label="Source Connection"
                            >
                                {/*<MenuItem value={0}>Select</MenuItem>*/}
                                {sources && sources.filter(s=>s.source.source==true).map(s => (
                                    <MenuItem value={s.id}>{s.name}</MenuItem>
                                ))}
                            </Select>}
                        </FormControl>

                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="source-connection-schema-id-label">Source Schema</InputLabel>
                            {<Select
                                labelId="source-connection-schema-id-label"
                                id="source-connection-schema-id"
                                value={selectedSchemaId}
                                onChange={(event) => {
                                    setSelectedSchemaId(Number.parseInt(event.target.value.toString()));
                                }}
                                label="Schema"
                                disabled={ schemaSelectDisabled }
                            >
                                {/*<MenuItem value={0}>Select</MenuItem>*/}
                                {clientSchemas && clientSchemas.map(clientSchema => (
                                    <MenuItem value={clientSchema.id}>{clientSchema.schemaName}{clientSchema.lastModified? ' - ' + moment(clientSchema.lastModified).format(`yyyy/MM/DD HH:mm:ss`): ''}</MenuItem>
                                ))}
                            </Select>}
                        </FormControl>

                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="destination-connection-id-label">Destination Connection</InputLabel>
                            {<Select
                                labelId="destination-connection-id-label"
                                id="destination-connection-id"
                                value={selectedDestinationConnectionId}
                                onChange={(event) => {
                                    // Clear out destination schema names
                                    setDestSchemaNames([]);
                                    setSelectedDestSchemaName('');
                                    setSelectedDestinationConnectionId(Number.parseInt(event.target.value.toString()));
                                }}
                                label="Destination Connection"
                            >
                                {/*<MenuItem value={0}>Select</MenuItem>*/}
                                {sources && sources.filter(s=>s.source.destination==true).map(s => (
                                    <MenuItem value={s.id}>{s.name}</MenuItem>
                                ))}
                            </Select>}
                        </FormControl>

                        <FormControl variant="outlined" className={classes.formControl}>
                            {(destSchemaLoading && "Getting destination schemas...") || !destSchemaSelectDisabled &&
                            <InputLabel id="dest-schema-name-label">Destination Schema</InputLabel> &&
                            <Select
                                labelId="dest-schema-name-label"
                                id="dest-schema-name"
                                value={selectedDestSchemaName}
                                onChange={(event) => {
                                    setSelectedDestSchemaName(event.target.value.toString());
                                }}
                                label="Destination Schema Name"
                            >
                                {/*<MenuItem value={0}>Select</MenuItem>*/}
                                {destSchemaNames && destSchemaNames.map((s, index) => (
                                    <MenuItem key={index} value={s}>{s}</MenuItem>
                                ))}
                            </Select>}
                        </FormControl>

                        <TextField 
                            className={classes.fullWidthField} 
                            style={{width: 200}}
                            id="outlined-basic" 
                            label="Table Prefix"
                            variant="outlined"
                            value={mappingPrefix}
                            onChange={(event) => {
                                setMappingPrefix(event.target.value);
                            }}
                            placeholder="Table Prefix" />

                            <br />
                            <small>Prefix to apply to tables on migration. For example, using the prefix "NR_", A table called Accounts would be created as NR_Accounts.</small>
                            <br />
                            <br />
                    </form>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <div>
                <Button 
                    variant="contained" 
                    className = {classes.button}
                    onClick={() => {
                        saveMetaMapping({
                            metaMappingId: getId(),
                            name: mappingName,
                            prefix: mappingPrefix,
                            sourceConnectionDestinationId: selectedDestinationConnectionId,
                            sourceConnectionOriginId: selectedSourceConnectionId,
                            clientSchemaId: selectedSchemaId,
                            destSchemaName: selectedDestSchemaName
                        }).then(res => {
                            if (id) {
                                history.push(`/sources-meta`);
                            } else {
                                history.push(`/sources-meta/setup/${res}`);
                            }
                        });
                    }}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default SourcesMetaEditView;