import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField, FormControlLabel, Checkbox, Radio, RadioGroup, FormLabel, Divider } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import { Source, SourceField } from "../../models/Source";
import { useEffect, useState } from "react";
import SourcesService from "../../services/SourcesServices";
import SourceConnectionService from "../../services/SourcesConnectionService";
import { useHistory, useParams } from "react-router";
import { SourceConnection } from "../../models/SourceConnection";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClientTableService from "../../services/ClientTableService";
import { ClientColumnViewModel } from "../../models/ClientColumn";
import WebhookService from "../../services/WebhookService";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#349CC9',
        color: 'white'
    },
    smallButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#349CC9',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '98%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '98%'
    },
    paper: {
        paddingTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        minWidth: 600,
    },
    textInput: {
        backgroundColor: theme.palette.common.white
    },
    nonfullWidthField: {
        width: '95%'
    },
    checkbox: {
        height: 28,
        '&.Mui-checked': {
            color: '#4e8095',
             }
    }
}));

const WebhookEditView: FC = (): ReactElement => {

    const { getWebhook, saveWebhook } = WebhookService();

    const history = useHistory();

    const { id } = useParams<{ id?: string }>();

    const classes = useStyles();
    const params = useParams() as any;

    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [restMethodList, setRestMethodList] = useState(['POST']);
    const [restMethod, setRestMethod] = useState<String>();
    const [contentTypeList, setContentTypeList] = useState(['application/json']);
    const [contentType, setContentType] = useState<string>();
    const [content, setContent] = useState('');

    const [useAuth, setUseAuth] = useState(false);
    const [selectedAuthOption, setSelectedAuthOption] = useState('none');
    const [basicAuthUser, setBasicAuthUser] = useState<string>();
    const [basicAuthPass, setBasicAuthPass] = useState<string>();
    const [apiKey, setApiKey] = useState<string>();

    // error flags
    const [webhookNameEmpty, setWebhookNameEmpty] = useState<boolean>();
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }


    const handleRadioChange = (event) => {
        setSelectedAuthOption(event.target.value);
      };

      const handleSaveClick = () => {
        let webhook = {id: getId(), name: name, url: url, contentType: contentType, content: content, basicUser: null, basicPass: null, apiKey: null}
        if(useAuth){
            if(selectedAuthOption == 'Basic'){
                webhook.basicUser = basicAuthUser;
                webhook.basicPass = basicAuthPass;
            }else{
                webhook.apiKey = apiKey;
            }
        }
        saveWebhook(webhook).then(res => {
            history.push('/webhooks')
        })
      };


    useEffect(() => {
        setRestMethod('POST');
        if(getId() == 0){
            setContentType('application/json');
        }else{
            getWebhook(getId()).then(res => {
                setName(res.name);
                setUrl(res.url);
                setContentType(res.contentType);
                setContent(res.content);
                if(res.basicUser != null){
                    setUseAuth(true);
                    setSelectedAuthOption('Basic');
                    setBasicAuthUser(res.basicUser);
                    setBasicAuthPass(res.basicPass);
                }else if(res.apiKey != null){
                    setUseAuth(true);
                    setSelectedAuthOption('API Key');
                    setApiKey(res.apiKey);
                }
            })
        }
        
    }, []);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                <form className={classes.root} noValidate autoComplete="off">
                        
                    <TextField 
                            className={classes.fullWidthField} 
                            id="outlined-basic" 
                            label="Webhook Name"
                            variant="outlined"
                            value={name}
                            error={webhookNameEmpty}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            placeholder="Webhook Name" 
                        />

                        <div style={{width: '50%', color: 'red', fontSize: '16px', marginLeft: 20}}>
                            {webhookNameEmpty && 'Webhook name cannot be blank'}
                        </div>

                        
                            <TextField style={{marginTop: '0px', marginBottom: '30px'}}
                                className={classes.nonfullWidthField} 
                                InputProps={{ className: classes.textInput }} 
                                id="outlined-basic" 
                                label="URL"
                                variant="filled"
                                size="small"
                                value={url}
                                onChange={(event) => {setUrl(event.target.value);}}
                                placeholder="URL" 
                            />
                       

                        <div style={{display:'inline'}}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={useAuth}
                                    onChange={() => {setUseAuth(!useAuth);}}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Set Authorization Header"
                            />
                        
                        <FormControl component="fieldset">                            
                            <RadioGroup row aria-label="position" name="position" value={selectedAuthOption} onChange={handleRadioChange}>
                                <FormControlLabel 
                                    value="Basic"
                                    disabled={!useAuth}  
                                    control={
                                        <Radio
                                            className={classes.checkbox}
                                            color="primary"
                                        />} 
                                    label="Basic" />
                                <FormControlLabel 
                                    value="API Key"
                                    disabled={!useAuth}  
                                    control={
                                        <Radio
                                            className={classes.checkbox}
                                            color="primary" 
                                        />} 
                                    label="API Key" />
                            </RadioGroup>
                        </FormControl>

                        {useAuth && selectedAuthOption == 'Basic' && <TextField style={{marginTop: '10px'}}
                                className={classes.nonfullWidthField} 
                                InputProps={{ className: classes.textInput }} 
                                id="outlined-basic" 
                                label="User"
                                variant="filled"
                                size="small"
                                value={basicAuthUser}
                                onChange={(event) => {setBasicAuthUser(event.target.value);}}
                                placeholder="User" 
                            />}

                        {useAuth && selectedAuthOption == 'Basic' && <TextField style={{marginTop: '10px'}}
                                className={classes.nonfullWidthField} 
                                InputProps={{ className: classes.textInput }} 
                                id="outlined-basic" 
                                label="Pass"
                                variant="filled"
                                size="small"
                                value={basicAuthPass}
                                onChange={(event) => {setBasicAuthPass(event.target.value);}}
                                placeholder="Pass" 
                            />}

                        {useAuth && selectedAuthOption == 'API Key' && <TextField style={{marginTop: '10px'}}
                                className={classes.nonfullWidthField} 
                                InputProps={{ className: classes.textInput }} 
                                id="outlined-basic" 
                                label="API Key"
                                variant="filled"
                                size="small"
                                value={apiKey}
                                onChange={(event) => {setApiKey(event.target.value);}}
                                placeholder="API Key" 
                            />}
                        

                        </div>

                        <div style={{marginLeft: 20, marginBottom: 20}}>
                        
                        </div>

                        <div style={{ display: 'flex'}}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                
                                {restMethodList && <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={restMethod ? restMethod : null}
                                    onChange={(e) => setRestMethod(restMethodList.find(s => s === e.target.value))}
                                    label="Name"
                                >
                                    {restMethodList && restMethodList.map(s => (
                                        <MenuItem value={s}>{s}</MenuItem>
                                    ))}
                                </Select>}
                            </FormControl>
                            <div>
                            <FormControl variant="outlined" className={classes.formControl}>
                                
                                {contentTypeList && <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={contentType ? contentType : null}
                                    onChange={(e) => setContentType(contentTypeList.find(s => s === e.target.value))}
                                    label="Name"
                                >
                                    {contentTypeList && contentTypeList.map(s => (
                                        <MenuItem value={s}>{s}</MenuItem>
                                    ))}
                                </Select>}
                            </FormControl>
                            </div>
                        </div>                    

                        <div>
                            <textarea
                                value={content}
                                onChange={(event) => {
                                    setContent(event.target.value);
                                }}
                                style={{fontSize: 16}}
                                rows={24}
                                cols={108}
                                placeholder="Content"
                            />
                        </div>

                        

                    </form>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <div>
                <Button 
                    variant="contained" 
                    className = {classes.button}
                    onClick={() => {
                        handleSaveClick()
                    }}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default WebhookEditView;