import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import UserService from "../../services/UserService";
import { User } from "../../models/UserModel";


const useStyles = makeStyles((theme) => ({
    root: {
        width: ''
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
}));

interface RemoveUserProps {
    show: boolean;
    setAlert: ({ title, message, severity }) => void;
    user: User;
    onCompletion: () => void;
    onClose: () => void;
}

const RemoveUser: FC<RemoveUserProps> = ({
    show = false,
    setAlert,
    user,
    onCompletion,
    onClose
}): ReactElement => {

    const { deleteUser } = UserService();
    const classes = useStyles();

    const removeUser = () => {
        deleteUser(user.user_id).then(res => {
            setAlert({title:'Success', message: user.email + ' was deleted', severity:'success'});
        }).catch(res => {
            setAlert({title:'Could Not Delete User', message: res.message, severity:'error'});
        }).finally(() => {
            onCompletion();
            handleClose();
        })
    }

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return (
        <Dialog
            open={show}
            keepMounted
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {user?.email + ' will be deleted'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={removeUser} color="primary"> Delete </Button>
                <Button onClick={handleClose} color="primary"> Cancel </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveUser;