import ApiClient from './apiClient';
import AppConfig from '../config';
import ApiResponse from '../models/ApiResponse';

const RemoteSchemaService = () => {    

    const { get, post } = ApiClient();

    const listSchemas = (id: number) => {
        return new Promise<ApiResponse<string[]>>((resolve, reject) => {
            get<ApiResponse<string[]>>(`${AppConfig.CamelApiBasePath}/listremoteschemas/${id}`).then(res => {
                console.log(res);
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const loadSchema = (id: number, schema: string) => {
        return new Promise<ApiResponse<string>>((resolve, reject) => {    
            post<ApiResponse<string>>(`${AppConfig.CamelApiBasePath}/loadremoteschema/${id}`, {schema}).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }
    
    return {
        listSchemas,
        loadSchema
    };

}

export default RemoteSchemaService;