import axios from 'axios';
import AppConfig from '../config';
import { ClientSchema, ClientSchemaViewModel } from '../models/ClientSchema';
import ApiClient from './apiClient';

const ClientSchemaService = () => {
    
    const { get, post } = ApiClient();

    const getAllClientSchemas = () => {
        return new Promise<ClientSchema[]>((resolve, reject) => {
            get<ClientSchema[]>(`${AppConfig.UiApiBasePath}/client-schemas`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getClientSchema = (id: any) => {
        return new Promise<ClientSchema>((resolve, reject) => {
            get<ClientSchema>(`${AppConfig.UiApiBasePath}/client-schemas/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getClientSchemasForConnection = (connectionid?: number) => {
        return new Promise<ClientSchema[]>((resolve, reject) => {
            let path = `${AppConfig.UiApiBasePath}/client-schemas`;
            if (connectionid) {
                path += `?connection=${connectionid}`;
            }
            get<ClientSchema[]>(path).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const save = (model: ClientSchemaViewModel) => {
        return new Promise<number>((resolve, reject) => {
            post<number>(`${AppConfig.UiApiBasePath}/client-schemas`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const deleteClientSchema = (id) => {
        return new Promise<number>((resolve, reject) => {
            post<number>(`${AppConfig.UiApiBasePath}/client-schemas/delete/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const updatePk = (tableId: number, columnIdArray: number[]) => {
        var model = {type: 'pk', table: tableId, columns: columnIdArray};
        return new Promise<number>((resolve, reject) => {
            post<number>(`${AppConfig.UiApiBasePath}/client-schemas/update`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const updateMd = (tableId: number, columnId: number) => {
        var model = {type: 'md', table: tableId, columns: [columnId]};
        return new Promise<number>((resolve, reject) => {
            post<number>(`${AppConfig.UiApiBasePath}/client-schemas/update`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        getAllClientSchemas,
        getClientSchema,
        getClientSchemasForConnection,
        save,
        deleteClientSchema,
        updatePk,
        updateMd
    }
}

export default ClientSchemaService;
