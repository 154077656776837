
import React, { FC, ReactElement, useEffect, useState } from "react";
import { Typography, makeStyles, CssBaseline, AppBar, Toolbar, IconButton, Badge, Drawer, Divider, List, Container, Grid, Paper, Box, Link, Avatar, CircularProgress, TableContainer, Table, TableHead, TableRow, TableBody, withStyles, TableCell, Button } from "@material-ui/core";
import C3Chart from 'c3';
import clsx from 'clsx';
import DashboardCounter from "../../components/DashboardCounter/DashboardCounter";
import { LogTotalSummary } from "../../models/LogTable";
import JobsServices from "../../services/JobsServices";
import moment from 'moment';
import { NavLink } from "react-router-dom";
import StatusService from "../../services/StatusService";
import RefreshIcon from '@material-ui/icons/Refresh';



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    paper2: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'row',
        alignItems: 'center'
    },
    fixedHeight: {
        height: 240,
    },
    primaryButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 125
    }
}));

const HomeView: FC = (): ReactElement => {

    const { getLogTotals } = JobsServices();
    const { getRunning } = StatusService();

    const [loading, setLoading] = useState(false);

    const [runningJob, setRunningJob] = useState<string>();

    const mountNode = document.getElementById('react-c3js');
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [chartData, setChartData] = useState<any>()
    const [chartAxis, setchartAxis] = useState<any>()

    const [jobTotals, setJobTotals] = useState<LogTotalSummary>();

    const data = {
        columns: [
            ['data1', 30, 200, 100, 400, 150, 250],
            ['data2', 50]
        ],
        type: `bar`
    };
    const axis = {
        x: {
            type: 'category',
            categories: ['cat1', 'cat2']
        }
    }

    useEffect(() => {
        getRunning().then(res => {
            setRunningJob(res.runningJob);
        })
    }, []);

    useEffect(() => {
        setLoading(true);
        getLogTotals().then(res => {
            setJobTotals(res);
            if (true) {

                var currentDate = Date.now();
                var outputDate = []
                for(i=9; i>-1; i--){
                    outputDate.push(moment(currentDate-(i*86400000)).utc().format('MM/DD/YYYY'));
                }
                var metadata = [], uniqueMappingIDs = [], l = res.totalMigratedRows10Days.length, i;
                for (i = 0; i < l; i++) {
                    if (metadata[res.totalMigratedRows10Days[i].id]) continue;
                    metadata[res.totalMigratedRows10Days[i].id] = true;

                    uniqueMappingIDs.push(res.totalMigratedRows10Days[i].id);
                }

                const metadataColumn = {};
                outputDate.forEach(date => {
                    metadataColumn[date] = [];
                    uniqueMappingIDs.forEach(m => {
                        const meta = res.totalMigratedRows10Days.find(mt => mt.id == m);
                        if (!metadataColumn[date][meta.name]) {
                            metadataColumn[date][meta.name] = [];
                        }
                        metadataColumn[date][meta.name].push(res.totalMigratedRows10Days.find(mt => mt.id == m && moment(mt.date).utc().format('MM/DD/YYYY') == date)?.total_rows || 0)
                    });
                });

                const categories = [];
                const newColumns = {};
                for(let p in metadataColumn) {
                    categories.push(p);
                    for(let ip in metadataColumn[p]) {
                        if (!newColumns[ip]) {
                            newColumns[ip] = [];
                        }
                        newColumns[ip].push(metadataColumn[p][ip])
                    }
                }
                const finalcolumns = [];
                for(let p in newColumns) {
                    finalcolumns.push([p].concat(newColumns[p]));
                }

                setchartAxis({
                    x: {
                        type: 'categories',
                        categories
                    }
                })

                setChartData({
                    columns: finalcolumns,
                    type: 'bar'
                })

                C3Chart.generate({
                    bindto: "#chart",
                    data: {
                        columns: finalcolumns,
                        type: "bar",
                      },
                    axis: {
                        x: {
                            type: 'categories',
                            categories
                        }
                    }
                  });
            }
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <>
            {loading && (
                <div style={{ textAlign: 'center' }}><CircularProgress /></div>
            )}
            {jobTotals && <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={classes.paper}>
                        <DashboardCounter link="/logs" title="Total Records" count={jobTotals.totalMigratedRows} />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={classes.paper}>
                        <DashboardCounter link="/sources-meta" title="Online Integrations" count={jobTotals.totalOnlineConnections} />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={classes.paper}>
                        <DashboardCounter link="/logs" title="Errors" count={0} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                <Paper className={classes.paper}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{
                                fontSize: 19,
                                color: 'grey',
                                width: '90%'
                            }}>
                                {(!runningJob && 'No jobs currently running') || 'Currently Running:'}
                            </div>
                            <Button 
                                className={classes.primaryButton}
                                variant="contained"
                                endIcon={<RefreshIcon />}
                                onClick={() => {
                                        getRunning().then(res => {
                                            setRunningJob(res.runningJob);
                                        });
                                    }
                                }>
                                Refresh
                            </Button>
                        </div>

                        <div style={{
                            fontSize: 19,
                            color: '#4e8095',
                            marginLeft: 15,
                            marginBottom: 10
                        }}>
                            {runningJob}
                        </div>

                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div style={{
                            fontSize: 22,
                            marginBottom: 20
                        }}>
                            Transaction Volume (10 days)
                        </div>
                        {chartData && <div id="chart" />}
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div style={{
                            fontSize: 22,
                            marginBottom: 20
                        }}>
                            Current Integrations
                        </div>
                        <TableContainer component={Paper}>
                            <Table style={{
                                width: '100%'
                            }} aria-label="customized table">
                                <TableBody>
                                    {jobTotals.currentIntegrations && jobTotals.currentIntegrations.map(jc => (
                                        <StyledTableRow>
                                            <StyledTableCell style={{
                                                fontSize: 20
                                            }}>
                                                <NavLink 
                                                    to={`/logs/${jc.jobid}`}
                                                >{jc.name}</NavLink>
                                            </StyledTableCell>
                                            <StyledTableCell style={{
                                                width: 200
                                            }}>
                                                <div>
                                                    {moment(jc.start_date).format('DD MMM yyyy HH:mm:ss')}
                                                </div>
                                                <div style={{
                                                    fontSize: 16,
                                                    paddingTop: 10
                                                }}>
                                                    Last Response
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>}
        </>
    )
}

export default HomeView;