import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Table, Checkbox,
    TableBody, TableContainer, TableHead, TableRow, Accordion, AccordionDetails, AccordionSummary, TablePagination, useForkRef, Tooltip, FormControl, Select, MenuItem } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { useHistory } from "react-router-dom";
import SourceConnectionService from "../../services/SourcesConnectionService";
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
import { Alert, AlertTitle } from "@material-ui/lab";
import MessageBox from "../../components/message/MessageBox";
import { SourceConnection } from "../../models/SourceConnection";
import UserService from "../../services/UserService";
import { User } from "../../models/UserModel";
import CreateUser from "../../components/management/CreateUser";
import RemoveUser from "../../components/management/RemoveUser";
import { grey } from "@material-ui/core/colors";
import moment from 'moment';
import WebhookService from "../../services/WebhookService";
import { Webhook, WebhookTrigger, WebhookTriggerViewModel } from "../../models/Webhook";
import MetaMappingService from "../../services/MetaMappingService";
import { MetaMapping } from "../../models/MetaMapping";
import EditIcon from '@material-ui/icons/Edit'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const OptionsTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    root: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.grey[300],
        borderLeftStyle: "solid",
      },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    table: {
        minWidth: 700,
    },
    primaryButton: {
        margin: theme.spacing(1),
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 120
    },
    secondaryButton: {
        marginRight: 10,
        backgroundColor: '#7CCCEE',
        color: 'white',
        minWidth: 120,
        fontSize: 11
    },
    deleteButton: {        
        backgroundColor: '#FF7A5A',
        color: 'white',
        minWidth: 120,
        fontSize: 11
    },
    editButton: {
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 125
    },
    checkbox: {
        height: 28,
        '&.Mui-checked': {
            color: '#4e8095',
             }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    }
  }));

const WebhookListView: FC = (): ReactElement => {
    
    const { getAllWebhooks, getWebhook, getAllWebhookTriggers, saveTrigger, deleteWebhook, deleteTrigger, sendWebhook} = WebhookService();
    const { getAllMetaMappings } = MetaMappingService();

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();

    const [webhooks, setWebhooks] = useState<Webhook[]>();                          // all webhooks
    const [webhook, setWebhook] = useState<Webhook>();                              // selected webhook
    const [webhookTriggers, setWebhookTriggers] = useState<WebhookTrigger[]>();     // all triggers for selected webhook
    const [metaMappings, setMetaMappings] = useState<MetaMapping[]>();              // all meta mappings
    const [selectedMetaMapping, setSelectedMetaMapping] = useState<MetaMapping>();  // selected meta mapping

    const [disableOptions, setDisableOptions] = useState(false);

    const [createTrigger, setCreateTrigger] = useState(false);
    const [triggerModel, setTriggerModel] = useState<WebhookTriggerViewModel>();

    const [addingUser, setAddingUser] = useState(false);
    const [removingUser, setRemovingUser] = useState(false);

    const [expandedAccordion, setExpandedAccording] = useState<string>();
    const [selectedWebhook, setSelectedWebhook] = useState<Webhook>();

    const [loadingDetails, setLoadingDetails] = useState(false);

    const [page, setPage] = useState(0);

    const anchorRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const loadWebhooks = () => {
        setLoading(true);
        setSelectedWebhook(null);
        setSelectedMetaMapping(null);
        setTriggerModel({id: 0, webhookId: 0, metaMappingId: 0, onStart: false, onCompletionSuccess: false, onCompletionError: false});
        setExpandedAccording(null);

        getAllMetaMappings().then(res => {
            setMetaMappings(res);
        }).finally(() => {
            getAllWebhooks().then(res => {
                setWebhooks(res);
            }).finally(() => {
                setLoading(false);
            });
        })
    }

    const loadTriggers = (id: number) => {
        setLoading(true);

        getAllWebhookTriggers(id).then(res => {
            setWebhookTriggers(res);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleAccordionChange = ( webhook: Webhook) => (event, isExpanded) => {
        setSelectedWebhook(null);
        setExpandedAccording(null);
        if(isExpanded){
            setLoadingDetails(true);
            getAllWebhookTriggers(webhook.id).then(res => {
                setWebhookTriggers(res);
            }).catch(error => {
                setWebhookTriggers(null);
            }).finally(() => {
                setExpandedAccording(webhook.name);
                setTriggerModel({...triggerModel, webhookId: webhook.id})
                setSelectedWebhook(webhook);
                setLoadingDetails(false);
            });
        }
      };

    const handleAccordingRefresh = () => {
        setExpandedAccording(null);
        setLoadingDetails(true);
        getAllWebhookTriggers(selectedWebhook.id).then(res => {
            setWebhookTriggers(res);
        }).catch(error => {
            setWebhookTriggers(null);
        }).finally(() => {
            setExpandedAccording(selectedWebhook.name);
            setLoadingDetails(false);
        });        
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };

     const createNewTrigger = () => {
            saveTrigger(triggerModel).finally(() => {
                toggleTriggerCreate();
                handleAccordingRefresh();
            })
      }

      const updateMetaMapping = (metaMapping: MetaMapping) => {
        setSelectedMetaMapping(metaMapping);
        setTriggerModel({...triggerModel, metaMappingId: metaMapping.id});
      }

      const updateOnStart = () => {
        setTriggerModel({...triggerModel, onStart: !triggerModel.onStart});
      }

      const updateOnCompletionSuccess = () => {
        setTriggerModel({...triggerModel, onCompletionSuccess: !triggerModel.onCompletionSuccess});
      }
      
      const updateOnCompletionErrors = () => {
        setTriggerModel({...triggerModel, onCompletionError: !triggerModel.onCompletionError});
      }

      const toggleTriggerCreate = () => {
        if(!createTrigger){
            setCreateTrigger(true);
        }else{
            setCreateTrigger(false);
            resetTriggerModel();
        }
      }

      const resetTriggerModel = () => {
        setSelectedMetaMapping(null);
        setTriggerModel({id: 0, webhookId: 0, metaMappingId: 0, onStart: false, onCompletionSuccess: false, onCompletionError: false});
      }

      const handleTriggerCreateToggle = () => {
        setMenuOpen(!menuOpen);
      };

      const createConditionsString = (trigger: WebhookTrigger) => {
        let condition = '';
        let hasPrevious = false;
        if(trigger.onStart){
            condition = 'On Start';
            hasPrevious = true;
        }
        if(trigger.onCompletionSuccess){
            condition = hasPrevious ? condition + ', On Successful Completion': 'On Successful Completion';
            hasPrevious = true;
        }
        if(trigger.onCompletionError){
            condition = hasPrevious ? condition + ', On Completion With Errors': 'On Completion With Errors';
        }
        return condition;
      }

      const handleEditMenuToggle = (webhook: Webhook, event) => {        
        
    };

    const handleTestClick = (webhookId: number) => {
        setDisableOptions(true);        
        sendWebhook(webhookId).finally(() => {
            setDisableOptions(false);
        });       
      };

    const handleWebhookDelete = (webhookId: number) => {
        setDisableOptions(true);
        deleteWebhook(webhookId).finally(() => {
            setDisableOptions(false);
            loadWebhooks();
        })
    }

    const handleTriggerDelete = (triggerId: number) => {
        deleteTrigger(triggerId).finally(() => {
            handleAccordingRefresh();
        })
    }

      

     useEffect(() => {
        setSelectedWebhook(null);
        setExpandedAccording(null);
     }, [page]);

    useEffect(() => {
        loadWebhooks();

    }, []);

    return (
        <div>
            <Grid container spacing={3} style={{minWidth: '1275px'}}>
                <Grid item xs={12}>
                <div style={{
                                width: '100%',
                                textAlign: 'right',
                                textTransform: 'uppercase'
                            }}>
                                <Button
                                    variant="contained"
                                    className={classes.primaryButton}
                                    endIcon={<>+</>}
                                    onClick={() => { history.push('/webhooks/add') }}
                                >
                                    New Webhook
                                </Button>
                            </div>  
                </Grid>
                <Grid item xs={12} style={{minWidth: '835px'}}>
                    {!loading && webhooks && webhooks.slice(page * 10, page * 10 + 10).map((webhook) => (
                        <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expandedAccordion === webhook.name} onChange={handleAccordionChange(webhook)}>
                            <AccordionSummary>
                                <div style={{
                                    width: '50%',
                                    textAlign: 'left',
                                }}>
                                    {webhook.name}
                                </div>
                                <div style={{
                                    color: '#2e4d59',
                                    width: '50%',
                                    textAlign: 'right',
                                }}>
                                
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>

                                <div style={{display:'inline', marginRight:'10px'}}>
                                <div style={{marginBottom:'5px'}}>
                                <Button 
                                    variant="contained" 
                                    className = {classes.editButton}
                                    disabled = {disableOptions}
                                    onClick={() => {handleTestClick(webhook.id)}}
                                    >
                                    Test
                                </Button>
                                </div>

                                <div style={{marginBottom:'5px'}}>
                                <Button
                                    variant="contained" 
                                    className={classes.editButton}
                                    disabled = {disableOptions}
                                    endIcon={<EditIcon />}
                                    onClick={() => {history.push(`/webhooks/edit/${webhook.id}`)}}
                                    >
                                        Edit
                                </Button>
                                </div>

                                <div>
                                <Button
                                    variant="contained"
                                    className={classes.editButton}
                                    disabled = {disableOptions}                                    
                                    onClick={() => {handleWebhookDelete(webhook.id)}}
                                    >
                                        Delete
                                </Button>
                                </div>
                                </div>

                                {!loading && <TableContainer>
                                    <Table className={classes.table} size='small' aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell style={{width: '35%'}}>Triggers</StyledTableCell>
                                                <StyledTableCell style={{width: '50%'}}></StyledTableCell>
                                                <StyledTableCell>
                                                    <Button
                                                        className={classes.editButton}
                                                        endIcon={<>+</>}
                                                        ref={anchorRef}
                                                        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={toggleTriggerCreate}
                                                        >
                                                        Add
                                                    </Button>
                                                </StyledTableCell>
                                             </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!loading && webhookTriggers && webhookTriggers.map((trigger) => (
                                            <StyledTableRow key={trigger.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {trigger.metaMapping.name}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {createConditionsString(trigger)}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Button
                                                        className={classes.editButton}
                                                        size="small"
                                                        ref={anchorRef}
                                                        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={() => {handleTriggerDelete(trigger.id)}}
                                                        >
                                                            Remove
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            ))}
                                            {loading && (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={4} style={{textAlign: 'center'}}>Loading...</StyledTableCell>
                                            </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={webhooks?.length || 0}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={handleChangePage}
                        //onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    {loading && 'Loading webhooks...'}
                </Grid>
                
            </Grid>
        

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>


            <Dialog
                open={createTrigger}
                keepMounted
                onClose={toggleTriggerCreate}
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                <DialogContent>
                    <div>
                        <div style={{marginBottom: '10px'}}>
                            Create Trigger
                        </div>

                        <FormControl variant="outlined" className={classes.formControl}>                           
                            {metaMappings && <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedMetaMapping ? selectedMetaMapping.id: null}
                                onChange={(e) => updateMetaMapping(metaMappings.find(m => m.id === e.target.value))}
                                label="metaMapping"
                            >
                                {metaMappings && metaMappings.map(s => (
                                    <MenuItem value={s.id}>{s.name}</MenuItem>
                                ))}
                            </Select>}
                            <div style={{marginBottom: '10px'}}></div>
                            {triggerModel && <div>
                            <Checkbox
                                className={classes.checkbox}
                                color="primary"
                                checked={triggerModel.onStart}
                                onChange={(e) => updateOnStart()}
                            />
                            On Start
                            </div>}
                            {triggerModel && <div>
                            <Checkbox
                                className={classes.checkbox}
                                color="primary"
                                checked={triggerModel.onCompletionSuccess}
                                onChange={(e) => updateOnCompletionSuccess()}
                            />
                            On Successful Completion
                            </div>}
                            {triggerModel && <div>
                            <Checkbox
                                className={classes.checkbox}
                                color="primary"
                                checked={triggerModel.onCompletionError}
                                onChange={(e) => updateOnCompletionErrors()}
                            />
                            On Completion With Errors
                            </div>}                    
                        </FormControl>                                          
                        
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={createNewTrigger} color="primary"> Save </Button>
                    <Button onClick={toggleTriggerCreate} color="primary"> Cancel </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default WebhookListView;