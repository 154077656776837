import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField, FormControlLabel, Checkbox, Radio, RadioGroup, FormLabel, Divider } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import { Source, SourceField } from "../../models/Source";
import { useEffect, useState } from "react";
import SourcesService from "../../services/SourcesServices";
import SourceConnectionService from "../../services/SourcesConnectionService";
import { useHistory, useParams } from "react-router";
import { SourceConnection } from "../../models/SourceConnection";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClientTableService from "../../services/ClientTableService";
import { ClientColumnViewModel } from "../../models/ClientColumn";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#349CC9',
        color: 'white'
    },
    smallButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#349CC9',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '98%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '98%'
    },
    paper: {
        paddingTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        minWidth: 600,
    },
    checkbox: {
        height: 28,
        '&.Mui-checked': {
            color: '#4e8095',
             }
    }
}));

const TableCreateView: FC = (): ReactElement => {
    
    const {
        getSourceConnection
    } = SourceConnectionService();

    const { getSources } = SourcesService();

    const { getClientTablesForSchema, save, getView } = ClientTableService();

    const { id } = useParams<{ id?: string }>();
    const { tableid } = useParams<{ tableid?: string }>();
    const classes = useStyles();
    const params = useParams() as any;
    const [sources, setSources] = useState<Source[]>();
    const [sourceConnection, setSourceConnection] = useState<SourceConnection>();
    const [selectedSource, setSelectedSource] = useState<Source>();
    const history = useHistory();

    const [existingTables, setExistingTables] = useState<{id: number, tableName: string}[]>();
    const [loadingTables, setLoadingTables] = useState(false);

    const [name, setName] = useState('');

    const [isVirtual, setIsVirtual] = useState(false);
    const [useQuery, setUseQuery] = useState(true);
    const [selectedTableId, setSelectedTableId] = useState<number>();
    const [selectedTableName, setSelectedTableName] = useState<string>();
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState('');

    const [columnArray, setColumnArray] = useState([{id: 0, columnName: '', columnLabel: '', ordinalPosition: 1, dataType: 'STRING', dataTypeSize: 'DEFAULT'}]);

    // error flags
    const [tableNameEmpty, setTableNameEmpty] = useState<boolean>();
    const [tableNameSpace, setTableNameSpace] = useState<boolean>();
    const [queryEmpty, setQueryEmpty] = useState<boolean>();
    const [filterEmpty, setFilterEmpty] = useState<boolean>();
    const [columnNameEmpty, setColumnNameEmpty] = useState<boolean>();
    const [columnNameSpace, setColumnNameSpace] = useState<boolean>();
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    const getTableId = () => {
        if(tableid){
            return Number.parseInt(tableid);
        }
        return 0;
    }

    const loadSources = (sourceConnection?: SourceConnection) => {
        if (sourceConnection) {
            setSourceConnection(sourceConnection);
            setName(sourceConnection.name)
            setSelectedSource({
                ...sourceConnection.source,
                fields: sourceConnection.source.fields.map(f => {
                    return {
                        ...f,
                        value: sourceConnection.fields.find(sf => sf.sourceField.id === f.id) ? sourceConnection.fields.find(sf => sf.sourceField.id === f.id).value : ''
                    }
                })
            });
        }
        getSources().then(res => {
            let returnedsources = res;
            if (sourceConnection) {
                returnedsources = returnedsources.map(rs => {
                    return {
                        ...rs,
                        fields: rs.fields.map(rf => {
                            return {
                                ...rf,
                                value: sourceConnection.fields && sourceConnection.fields.find(scf => scf.sourceField.id == rf.id) ? sourceConnection.fields.find(scf => scf.sourceField.id == rf.id).value : ''
                            }
                        })
                    }
                })
            }
            setSources(returnedsources);
        })
    }

    useEffect(() => {
        if(getTableId() > 0){
            getView(getTableId()).then(tableView => {
                
                setName(tableView[0].tableName);
                setIsVirtual(!tableView[0].isTable);
                setUseQuery(tableView[0].sourceTable === 0);
                setQuery(tableView[0].query);
                setFilter(tableView[0].filter);

                const columns: ClientColumnViewModel[] = [];
                tableView[0].columns.forEach(e => {
                    columns.push({
                        id: e.id,
                        columnName: e.columnName,
                        columnLabel: e.columnLabel,
                        ordinalPosition: e.ordinalPosition,
                        dataType: e.dataType,
                        dataTypeSize: e.dataTypeSize
                    })                
                });
                columns.sort((a,b) => {
                    if(a.ordinalPosition > b.ordinalPosition){
                        return 1;
                    }else if(a.ordinalPosition < b.ordinalPosition){
                        return -1;
                    }else{
                        return 0;
                    }
                })

                setColumnArray(columns);
            })
        }

        let id = getId();

        setLoadingTables(true);

        getClientTablesForSchema(id).then(clientTables => {
            const tables: { id: number, tableName: string }[] = [];
            clientTables.forEach(d => {
                if(!d.userCreated){
                    tables.push({
                        id: d.id,
                        tableName: d.tableName
                    })
                }                
            });
            tables.sort((a,b) => {
                if(a.tableName > b.tableName) {
                    return 1;
                } else if(a.tableName < b.tableName) {
                    return -1;
                } else {
                    return 0;
                }
            })
            
            setExistingTables(tables);
                        
        }).finally(() => {
                setLoadingTables(false);
            });
    }, []);

    useEffect(() => {
        if(existingTables){
            setSelectedTableId(existingTables[0].id);
            setSelectedTableName(existingTables[0].tableName);
        }
    }, [existingTables]);

    const handleChange = () => {
        setIsVirtual(!isVirtual);
      };

      const handleRadioChange = () => {
        setUseQuery(!useQuery);
      };

    const handleIncrement = () => {
        setColumnArray([...columnArray, {id: 0, columnName: '', columnLabel: '', ordinalPosition: columnArray.length + 1, dataType: 'STRING', dataTypeSize: 'DEFAULT'}]);
      };
    
      const handleDecrement = () => {
        let updatedCount = columnArray.length - 1;
        if(updatedCount >= 1){
            setColumnArray(columnArray.slice(0, -1));
        }
      };

      const handleColumnTextBoxChange = (index, e) => {
        const updatedColumns = [...columnArray];
        updatedColumns[index].columnName = e.target.value;
        setColumnArray(updatedColumns);
      };

      const handleColumnDropdownChange = (index, e) => {
        const updatedColumns = [...columnArray];
        updatedColumns[index].dataType = e.target.value;
        setColumnArray(updatedColumns);
      };

      const handleTableDropdownChange = (e) => {
        setSelectedTableId(e.target.value);
        setSelectedTableName(e.target.options[e.target.selectedIndex].text);
      };

      const handleSaveClick = () => {

        let table = {id: getTableId(), schemaId: getId(), tableName: name, tableLabel: "", isTable: !isVirtual, sourceTable: 0, filter: "", query: "", userCreated: true, columns: []};

        if(isVirtual){
            if(useQuery){
                table.query = query;
                table.columns = columnArray;
            }else{
                table.sourceTable = selectedTableId;
                table.filter = filter;
            }
        }else{
            table.columns = columnArray;
        }

        console.log(table);

        if(validateTable(table)){
            save(table).then(ref => {
                if(ref){
                    history.push(`/schemas/${getId()}`)
                }
            })
        }
      };

      const validateTable = (table) => {
        resetErrorFlags();
        let hasError = false;

        if(table.tableName === ''){setTableNameEmpty(true); hasError = true;}
        else if(table.tableName.indexOf(' ') >= 0){setTableNameSpace(true); hasError = true;}

        if(!table.isTable){
            if(table.sourceTable === 0 && table.query === ''){
                setQueryEmpty(true); 
                hasError = true;
            }
        }

        for(const column of table.columns){
            if(column.columnName === ''){
                setColumnNameEmpty(true);
                hasError = true;
            }
            else if(column.columnName.indexOf(' ') >= 0){
                setColumnNameSpace(true);
                hasError = true;
            }
        }

        if(hasError){return false;}

        return true;
      }

      const resetErrorFlags = () => {
        setTableNameEmpty(false);
        setTableNameSpace(false);
        setQueryEmpty(false);
        setColumnNameEmpty(false);
        setColumnNameSpace(false);
      }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                <Paper className={classes.paper}>
                    <form className={classes.root} noValidate autoComplete="off">
                        
                        <TextField 
                            className={classes.fullWidthField} 
                            id="outlined-basic" 
                            label="Table Name"
                            variant="outlined"
                            value={name}
                            error={tableNameEmpty || tableNameSpace}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            placeholder="Table Name" 
                        />

                        <div style={{width: '50%', color: 'red', fontSize: '16px', marginLeft: 20}}>
                            {tableNameEmpty && 'Table name cannot be blank'}
                            {tableNameSpace && 'Table name cannot contain spaces'}
                        </div>

                        <div style={{marginBottom: 20}}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                className={classes.checkbox}
                                checked={isVirtual}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                            }
                            label="Virtual table"
                        />
                        </div>

                        <div style={{marginLeft: 20, marginBottom: 20}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" disabled={!isVirtual} >Create table from: </FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue="Result set" onChange={handleRadioChange}>
                                <FormControlLabel 
                                    value="Result set"
                                    disabled={!isVirtual}  
                                    control={
                                        <Radio
                                            className={classes.checkbox}
                                            color="primary"
                                        />} 
                                    label="Result set" />
                                <FormControlLabel 
                                    value="Existing table"
                                    disabled={!isVirtual}  
                                    control={
                                        <Radio
                                            className={classes.checkbox}
                                            color="primary" 
                                        />} 
                                    label="Existing table" />
                            </RadioGroup>
                        </FormControl>
                        </div>

                        {isVirtual && useQuery && <div>
                            <textarea
                                value={query}
                                onChange={(event) => {
                                    setQuery(event.target.value);
                                }}
                                style={{fontSize: 16}}
                                rows={12}
                                cols={108}
                                placeholder="Query"
                            />
                            <div style={{width: '100%', color: 'red', fontSize: '16px', marginLeft: 20}}>{queryEmpty && 'Query cannot be blank'}</div>
                        </div>}

                        {isVirtual && !useQuery && <div style={{width: '98%'}}>
                            <select
                                value={selectedTableId}
                                onChange={e => handleTableDropdownChange(e)}
                                >
                                {existingTables.map((option) => (
                                    <option key={option.id} value={option.id}>{option.tableName}</option>
                                ))}
                             </select>

                             <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 16}}>

                                <div style={{marginLeft: 0, marginRight: 10, fontSize: 16, paddingTop: 4}}>WHERE</div>

                                <textarea
                                    value={filter}
                                    onChange={(event) => {
                                        setFilter(event.target.value);
                                    }}
                                    style={{fontSize: 16}}
                                    rows={6}
                                    cols={100}
                                    placeholder="WHERE clause"
                                />

                             </div>
                        </div>}

                        <Divider style={{width: '98%', marginTop: 16}} />

                        {!(isVirtual && !useQuery) && <div style={{ display: 'flex', flexDirection: 'column', width: '98%' }}>

                            <div style={{display:'flex', justifyContent:'center', marginBottom: 12}}>
                                <Button 
                                    variant="contained"
                                    className = {classes.smallButton}
                                    size="small"
                                    onClick={() => {
                                        handleDecrement();
                                    }}>
                                    <RemoveIcon/>
                                </Button>
                                <Button 
                                    variant="contained"
                                    className = {classes.smallButton}
                                    size="small"
                                    onClick={() => {
                                        handleIncrement();
                                    }}>
                                    <AddIcon/>
                                </Button>
                            </div>

                            <div style={{color: 'red', fontSize: '16px', marginLeft: '20px', marginBottom: 8}}>{columnNameEmpty && 'Column names cannot be blank'}</div>
                            <div style={{color: 'red', fontSize: '16px', marginLeft: '20px', marginBottom: 8}}>{columnNameSpace && 'Column names cannot contain spaces'}</div>

                            {columnArray.map((column, index) => (
                            <div key={column.ordinalPosition} style={{ display: 'flex', marginBottom: 8 }}>
                                <input
                                    type="text"
                                    size={50}
                                    value={column.columnName}
                                    placeholder={`Column ${column.ordinalPosition}`}
                                    onChange={e => handleColumnTextBoxChange(index, e)}
                                />
                                <select
                                    value={column.dataType}
                                    onChange={e => handleColumnDropdownChange(index, e)}
                                    style={{ marginLeft: 12 }}
                                    >
                                    <option value="STRING">STRING</option>
                                    <option value="INTEGER">INTEGER</option>
                                    <option value="BOOLEAN">BOOLEAN</option>
                                    <option value="DATETIME">DATETIME</option>
                                    <option value="DATE">DATE</option>
                                    <option value="CURRENCY">CURRENCY</option>
                                    <option value="FLOAT">FLOAT</option>
                                    <option value="PERCENT">PERCENT</option>
                                    <option value="CURRENCY_HIGH_PRECISION">CURRENCY_HIGH_PRECISION</option>
                                    <option value="CLOBTEXT">CLOBTEXT</option>
                                    <option value="KEY">KEY</option>
                                    <option value="DURATION">DURATION</option>
                                </select>
                            </div>
                            ))}
                        </div>}

                    </form>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <div>
                <Button 
                    variant="contained" 
                    className = {classes.button}
                    onClick={() => {
                        handleSaveClick()
                    }}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default TableCreateView;