import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement, useCallback } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import MetaMappingService from "../../services/MetaMappingService";
import { useHistory } from "react-router";
import { MetaMapping } from "../../models/MetaMapping";
//import Cron from "react-js-cron";
import Cron from "react-cron-generator";
import JobsServices from "../../services/JobsServices";
import { useParams } from "react-router-dom";
import { Job } from "../../models/Job";
//import '/node_modules/react-js-cron/src/styles.css'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#349CC9',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

const JobEditView: FC = (): ReactElement => {

    const { getAllMetaMappings } = MetaMappingService();

    const { getJob, saveJob } = JobsServices();

    const { id } = useParams<{ id?: string }>();
    const classes = useStyles();
    const history = useHistory();
    const [job, setJob] = useState<Job>();
    const [jobName, setJobName] = useState('');
    const [mappingPrefix, setMappingPrefix] = useState('');
    const [mapping, setMapping] = useState<MetaMapping>();
    const [mappings, setMappings] = useState<MetaMapping[]>();
    const [cronExpression, setCronExpression] = useState('');
    const [selectedMappingId, setSelectedMappingId] = useState<number>(0);
    const [nameEditDisabled, setNameEditDisabled] = useState(false);

    const customSetValue = useCallback(
        (newValue: string) => {
          setCronExpression(newValue)
        },
        []
      )

    useEffect(() => {
        //console.log(cronExpression)
    }, [cronExpression])

    useEffect(() => {
        if (id) {
            setNameEditDisabled(true)
            getJob(Number.parseInt(id)).then(job => {
                setJobName(job.name);
                setJob(job);
                setCronExpression(job.cronExpression)

                getAllMetaMappings().then(res => {
                    setMappings(res);
                });
            });
        } else {
            getAllMetaMappings().then(res => {
                setMappings(res);
            })
        }
    }, []);

    useEffect(() => {
        if (mappings && job) {
            setSelectedMappingId(job.metaMappingId);
        }
    }, [mappings])

    const getJobId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <TextField 
                            disabled={nameEditDisabled}
                            className={classes.fullWidthField} 
                            id="job-name" 
                            label="Job Name"
                            variant="outlined"
                            value={jobName}
                            onChange={(event) => {
                                setJobName(event.target.value);
                            }}
                            placeholder="Job Name" />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="metaMappingId-label">Source Mapping</InputLabel>
                            <Select
                                labelId="metaMappingId-label"
                                id="metaMappingId"
                                value={selectedMappingId}
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setSelectedMappingId(Number.parseInt(event.target.value.toString()));
                                    }
                                }}
                                label="Mapping"
                            >
                                <MenuItem value={0}>Select a mapping</MenuItem>
                                {mappings && mappings.map(s => (
                                    <MenuItem value={s.id}>{s.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{
                            //borderRadius: 4,
                            //border: '1px solid #ccc',
                            width: '98%',
                            padding: 10
                        }}>
                            <div style={{ position: 'relative' }}>
                                <Cron
                                //className='my-project-cron'
                                //clearButtonProps={{
                                    //type: 'default',
                                //}} 
                                value={cronExpression}
                                showResultText='true'
                                showResultCron='true'
                                onChange={(c) => {
                                    setCronExpression(c);
                                }} />
                            </div>
                        </div>
                       
                            <div style={{width: '100%'}}>Job times are in UTC.</div>
                            <div style={{width: '100%'}}>Custom cron expressions must use Quartz syntax. See <a href="http://www.quartz-scheduler.org/documentation/quartz-2.3.0/tutorials/crontrigger.html" target="_blank" rel="noopener noreferrer">documentation</a> for formatting guidance.</div>
                        
                            <br />
                            <br />
                    </form>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <div>
                <Button 
                    variant="contained" 
                    className = {classes.button}
                    onClick={() => {
                        saveJob({
                            jobId: getJobId(),
                            cronExpression: cronExpression,
                            metaMappingId: selectedMappingId,
                            name: jobName
                        }).then(res => {
                            history.push(`/jobs`)
                        });
                    }}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default JobEditView;