import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem,
     Paper, Select, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, Grow,
      Popper, MenuList, ClickAwayListener, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { SourceConnection } from "../../models/SourceConnection";
import ClientSchemaService from "../../services/ClientSchemaService";
import { ClientSchema } from "../../models/ClientSchema";
import { NavLink } from "react-router-dom";
import ClientTableService from "../../services/ClientTableService";
import { ClientTable } from "../../models/ClientTable";
import ColumnView from "../../components/schema/ColumnView";
import ClientColumnService from "../../services/ClientColumnService";
import { ClientColumnSimple } from "../../models/ClientColumn";
import EditIcon from '@material-ui/icons/Edit'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PersonIcon from '@material-ui/icons/Person';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    },
    primaryButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 125
    },
    editButton: {
        backgroundColor: '#349CC9',
        color: 'white',
    },
    textButton: {
        variant: "text",
        fontSize: 14,
        color: '#4e8095',
        cursor: 'pointer'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    },
    checkbox: {
        height: 28,
        '&.Mui-checked': {
            color: '#4e8095',
             }
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


    const TableListView: FC = (): ReactElement => {

    const { getClientColumnsForTable } = ClientColumnService();
    const { getClientTablesForSchema, remove } = ClientTableService();
    const { getClientSchema, updatePk, updateMd } = ClientSchemaService();

    const { id } = useParams<{ id?: string }>();

    const history = useHistory();
    const classes = useStyles();
    const params = useParams() as any;

    const [sourceConnection, setSourceConnection] = useState<SourceConnection>();
    const [clientSchema, setClientSchema] = useState<ClientSchema>();

    const [clientTables, setClientTables] = useState<{ tableName: string, id: number, tableLabel: string, userCreated: boolean }[]>();
    const [clientTablesFiltered, setClientTablesFiltered] = useState<{ tableName: string, id: number, tableLabel: string, userCreated: boolean }[]>();

    const [loadingTables, setLoadingTables] = useState(false);
    const [loadingColumns, setLoadingColumns] = useState(false);
    const [clientColumns, setClientColumns] = useState<ClientColumnSimple[]>();

    const [expandedAccordion, setExpandedAccordion] = useState(0);  // id of selected table
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState<string>();
    const [refreshToggle, setRefreshToggle] = useState(false);

    const [selectedTable, setSelectedTable] = useState<{ tableName: string, id: number, tableLabel: string, userCreated: boolean }>();
    const [selectedPKColumns, setSelectedPKColumns] = useState<number[]>([]);   // columns selected for primary key
    const [selectedMDColumn, setSelectedMDColumn] = useState(0);                // column selected for modified date

    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState()
    const prevMenuOpen = useRef(menuOpen);

    const [pkEdit, setPkEdit] = useState(false);
    const [mdEdit, setMdEdit] = useState(false);
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    useEffect(() => {
        let id = getId();

        getClientSchema(id).then(clientSchema => {

            setClientSchema(clientSchema);
            setSourceConnection(clientSchema.sourceConnectionOrigin);

            setLoadingTables(true);

            getClientTablesForSchema(id).then(clientTables => {
                const tables: { tableName: string, id: number, tableLabel: string, userCreated: boolean }[] = [];
                clientTables.forEach(d => {
                    tables.push({
                        tableName: d.tableName,
                        id: d.id,
                        tableLabel: d.tableLabel,
                        userCreated: d.userCreated
                    })                    
                });
                tables.sort((a,b) => {
                    if(a.tableName > b.tableName) {
                        return 1;
                    } else if(a.tableName < b.tableName) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
            
                setClientTables(tables);
                setClientTablesFiltered(tables);
                        
            }).finally(() => {
                setLoadingTables(false);
            })
        });        
    }, [refreshToggle])

    useEffect(() => {
        if (search) {
            setClientTablesFiltered(clientTables.filter(s => s.tableName.toLowerCase().includes(search.toLowerCase())));
        } else {
            setClientTablesFiltered(clientTables);
        }
        setPage(0);
    }, [search]);

    useEffect(() => {
        if(anchorRef.current){
            setAnchorEl(anchorRef.current);
        }
    }, [anchorRef.current])

    const changeSelectedPKColumnStatus = (id: number) => {
        if (!selectedPKColumns.find(f => f === id)) {
            setSelectedPKColumns(selectedPKColumns.concat([id]));
        } else {
            setSelectedPKColumns(selectedPKColumns.filter(t => t !== id));
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
     };

     const handleAccordionChange = (table) => (event, isExpanded) => {    
        closeAccordion();
        if(isExpanded){
            openAccordion(table);
        }else{
            setSelectedTable({tableName: '', id: 0, tableLabel: '', userCreated: false});
        }
      };

      const closeAccordion = () => {
        setMenuOpen(false);
        setExpandedAccordion(0);
      }

      const openAccordion = (table) => {
        setLoadingColumns(true);
        setSelectedTable(table);
        getClientColumnsForTable(table.id).then(columns => {
            setClientColumns(columns);
        }).finally(() => {
            setLoadingColumns(false);
            setExpandedAccordion(table.id);
        })
      }

      const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
      };
    
      const handleMenuClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }  
        setMenuOpen(false);
      };

      const savePK = () => {
        updatePk(selectedTable.id, selectedPKColumns).finally(() => {
            togglePkEdit();
            closeAccordion();
            openAccordion(selectedTable);
        })
      }

      const togglePkEdit = () => {
        if(!pkEdit){
            var sc = [];
            for(let i = 0; i < clientColumns.length; i++){
                if(clientColumns[i].primaryKey == true){
                    sc.push(clientColumns[i].id)
                }
            }
            setSelectedPKColumns(sc);
            setPkEdit(true);
        }else{
            setPkEdit(false);
            setSelectedPKColumns([]);
        }
      }

      const saveMD = () => {
        updateMd(selectedTable.id, selectedMDColumn).finally(() => {
            toggleMdEdit();
            closeAccordion();
            openAccordion(selectedTable);
        })
      }

      const toggleMdEdit = () => {
        if(!mdEdit){
            for(let i = 0; i < clientColumns.length; i++){
                if(clientColumns[i].lastModified == true){
                    setSelectedMDColumn(clientColumns[i].id);
                    break;
                }
            }
            setMdEdit(true);
        }else{
            setMdEdit(false);
            setSelectedMDColumn(0);
        }
      }

      const handleRemove = (id: number) => {
        remove(id).then(res => {
            if(res){
                console.log(id + ' removed');
                setRefreshToggle(!refreshToggle);
            }
        })
      }
    
    return (
        <div>
            
        <div style={{ fontSize: 20, marginBottom: 20 }}>
            {!loadingTables && sourceConnection?.name + ': ' + clientSchema?.schemaName}
        </div>

        <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10}}>

            <div style={{ width: '90%'}}>
                Search: <input 
                    type="text"
                    size={40}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }} />
            </div>

            <div style={{ justifyContent: 'right'}}>
                <Button
                    variant="contained"
                    className={classes.primaryButton}
                    disabled={!(sourceConnection?.source.name=='NetSuite')} // currently only able to create tables for netsuite
                    endIcon={<PlaylistAddIcon />}
                    onClick={() => {
                        history.push(`/schemas/${getId()}/add`)
                    }}
                >
                    Add
                </Button>
            </div>
        
        </div>

        <div>    
            {!loadingTables && clientTablesFiltered && clientTablesFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((table) => (                            
                        <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expandedAccordion === table.id} onChange={handleAccordionChange(table)}>
                            <AccordionSummary>
                                <div style={{
                                    color: '#2e4d59',
                                    width: '5%',
                                    textAlign: 'left',
                                }}>
                                    {loadingColumns && selectedTable && selectedTable.id === table.id && <CircularProgress style={{ width: 24, height: 24 }} />} </div>
                                <div style={{
                                    color: '#2e4d59',
                                    width: '45%',
                                    textAlign: 'left',
                                }}>
                                    {table.tableName}</div>
                                <div style={{
                                    color: '#7f939b',
                                                width: '45%',
                                    textAlign: 'left',
                                }}>
                                    {table.tableLabel}</div>
                                <div style={{
                                    color: '#2e4d59',
                                    width: '5%',
                                    textAlign: 'left',
                                }}>
                                    {table.userCreated && (<Tooltip title="User-added" placement="right-start"><PersonIcon/></Tooltip>)}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {!loadingColumns && <TableContainer>
                                    <Table className={classes.table} size='small' aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell style={{width: '5%'}}>
                                                    <Button
                                                        className={classes.editButton}
                                                        endIcon={<EditIcon />}
                                                        ref={anchorRef}
                                                        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleMenuToggle}
                                                        >
                                                        Edit
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell style={{width: '5%'}}>Pos.</StyledTableCell>
                                                <StyledTableCell>Column Name</StyledTableCell>
                                                <StyledTableCell>Label</StyledTableCell>
                                                <StyledTableCell>Data Type</StyledTableCell>
                                             </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!loadingColumns && clientColumns && clientColumns.map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell component="th" scope="row" style={{textAlign: 'center'}}>
                                                    {row.primaryKey && <Tooltip title="Primary Key" placement="left-start">
                                                        <button
                                                        style={{ background: 'none', border: 'none', cursor: 'default' }}
                                                        >
                                                        <VpnKeyIcon style={{ color: '#ee9e7c' }}/>
                                                        </button>
                                                    </Tooltip>}
                                                    {row.lastModified && <Tooltip title="Modified Date" placement="left-start">
                                                        <button
                                                        style={{ background: 'none', border: 'none', cursor: 'default' }}
                                                        >
                                                        <ChangeHistoryIcon style={{ color: '#ee9e7c' }}/>
                                                        </button>
                                                    </Tooltip>}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.ordinalPosition}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.columnName}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.columnLabel}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.dataType}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            ))}
                                            {loadingColumns && (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={4} style={{textAlign: 'center'}}>Loading...</StyledTableCell>
                                            </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>}                                        
                            </AccordionDetails>
                        </Accordion>           
                ))}
                {loadingTables && <CircularProgress style={{ width: 32, height: 32 }} />}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={clientTablesFiltered?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>

            <Popper open={menuOpen} anchorEl={anchorEl} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                                {selectedTable.userCreated && 
                                    <MenuItem  onClick={() => { history.push(`/schemas/${getId()}/edit/${selectedTable.id}`)}}>Modify</MenuItem>}
                                {selectedTable.userCreated && 
                                    <MenuItem  onClick={() => { handleRemove(selectedTable.id) }}>Delete</MenuItem>}
                                <MenuItem onClick={togglePkEdit} >Set Primary Key</MenuItem>
                                <MenuItem onClick={toggleMdEdit} >Set Modified Date</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>

            <Dialog
                open={pkEdit}
                keepMounted
                onClose={togglePkEdit}
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                <DialogContent>
                    <div>
                        <div style={{marginBottom: '10px'}}>
                            Select all columns that form the primary key
                        </div>
                        {!loadingColumns && clientColumns && clientColumns.map((column) => {
                            var selected = selectedPKColumns.includes(column.id);
                            return(
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={{minWidth: 40}}>
                                    <Checkbox
                                        className={classes.checkbox}
                                        color="primary"
                                        checked={selected}
                                        onChange={(event) => {
                                            changeSelectedPKColumnStatus(column.id);
                                        }}
                                    />
                                </div>
                                <div style={{
                                    minWidth: 400,
                                    height: 30,
                                    display: 'flex',
                                    flexDirection:'column',
                                    justifyContent: 'center'
                                }}>
                                    {(selected && column.columnName)||(<div style={{color: 'lightgrey'}}>{column.columnName}</div>)}
                                </div>
                            </div>
                            )                         
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={savePK} color="primary"> Save </Button>
                    <Button onClick={togglePkEdit} color="primary"> Cancel </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={mdEdit}
                keepMounted
                onClose={toggleMdEdit}
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                <DialogContent>
                <div>
                    <div style={{marginBottom: '10px'}}>
                        Select the column used to track modified date
                    </div>
                    <FormControl variant="outlined" className={classes.formControl}>
                            
                            {clientColumns && <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedMDColumn}
                                onChange={(e) => setSelectedMDColumn(e.target.value === 0 ? 0 : clientColumns.find(s => s.id === e.target.value).id)}
                                label="ModifiedDate"
                            >
                                <MenuItem value={0}>- NO MODIFIED DATE -</MenuItem>
                                {clientColumns && clientColumns.map(s => (
                                    <MenuItem value={s.id}>{s.columnName}</MenuItem>
                                ))}
                            </Select>}
                        </FormControl>
                    </div>                            
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveMD} color="primary"> Save </Button>
                    <Button onClick={toggleMdEdit} color="primary"> Cancel </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TableListView;