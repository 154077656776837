

import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    noMaxWidth: {
      maxWidth: 'none',
    },
  }));


const tooltipStyle = {
    fontSize: '14px',
    maxWidth: 'none'
  };

const TruncateWithTooltip = ({ text, maxLength }) => {
    const classes = useStyles();


  const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  const tooltipTitle = (
    <Typography style={{fontSize: 15}}>{text}</Typography>
  );

  return (
    <Tooltip title={text.length > maxLength ? tooltipTitle: ''} classes={{ tooltip: classes.noMaxWidth }}>
      <div>{truncatedText}</div>
    </Tooltip>
  );
};

export default TruncateWithTooltip;