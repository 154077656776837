import AppConfig from '../config';
import { Job } from '../models/Job';
import { CurrentIntegration, Integration, LogTable, LogTotalSummary } from '../models/LogTable';
import ApiClient from './apiClient';
import { ExtractionProgress } from '../models/ExtractionProgress';
import { ExtractionLoginResponse } from '../models/ExtractionLoginResponse';
import { StringDecoder } from 'string_decoder';
import { ExtractionStartResponse } from '../models/ExtractionStartResponse';

const ExtractionService = () => {
    
    const { get, post } = ApiClient();

    const getProgress = (sourcename: string) => {
        return new Promise<ExtractionProgress>((resolve, reject) => {
            //console.log("Sourcename: " + sourcename);
            let path = '';
            if (sourcename === 'NetSuite') { path = 'nsextraction'; }
            if (sourcename.startsWith('Salesforce')) { path = 'sfextraction'; }
            get<ExtractionProgress>(`${AppConfig.CamelApiBasePath}/` + path + `/progress`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    // NetSuite only
    const login = (model: {connector: number, email: string, password: string}) => {
        return new Promise<ExtractionLoginResponse>((resolve, reject) => {    
            post<ExtractionLoginResponse>(`${AppConfig.CamelApiBasePath}/nsextraction/login`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const start = (id, model: {auth: string}, sourcename: string) => {
        return new Promise<ExtractionStartResponse>((resolve, reject) => {  
            let path = '';
            if (sourcename === 'NetSuite') { path = 'nsextraction'; }
            if (sourcename.startsWith('Salesforce')) { path = 'sfextraction'; }
            post<ExtractionStartResponse>(`${AppConfig.CamelApiBasePath}/` + path + `/start/${id}`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    // NetSuite only
    const cancel = (id) => {
        return new Promise<string>((resolve, reject) => {    
            post<string>(`${AppConfig.CamelApiBasePath}/nsextraction/remove/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const stop = (sourcename: string) => {
        return new Promise<string>((resolve, reject) => {    
            let path = '';
            if (sourcename === 'NetSuite') { path = 'nsextraction'; }
            if (sourcename.startsWith('Salesforce')) { path = 'sfextraction'; }
            post<string>(`${AppConfig.CamelApiBasePath}/` + path + `/stop`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        getProgress,
        login,
        start,
        cancel,
        stop
    };
}

export default ExtractionService;