import { Typography, makeStyles, CssBaseline, AppBar, Toolbar, IconButton, Badge, Drawer, Divider, List, Container, Grid, Paper, Box, Link, Avatar, Button } from "@material-ui/core";
import React, { FC, ReactElement, useContext, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import 'c3/c3.css';

import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { adminListItems, mainListItems, secondaryListItems } from './listItems';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import HomeView from "./views/Home/home";
import SourcesListView from "./views/Sources/SourcesListView";
import Logo from './assets/logo2.png';
import './App.css';
import SourcesObjectsView from "./views/SourceObjects/SourcesObjectsView";
import JobsListView from "./views/Jobs/JobsListView";
import { UserContext } from "./providers/userProvider";
import SignIn from "./views/SignIn/signIn";
import { useAuth0 } from "@auth0/auth0-react";
import SourcesMetaEditView from "./views/SourceMeta/SourcesMetaEditView";
import SourcesMetaTableEditView from "./views/SourceMetaTables/SourcesMetaTablesEditView";
import SourcesMetaTableFieldsEditView from "./views/SourceMetaTablesFields/SourcesMetaTablesFieldsEditView";
import SourceConnectionEditView from "./views/SourcesConnection/SourceConnectionEditView";
import SourceConnectionListView from "./views/SourcesConnection/SourceConnectionListView";
import SourceMetaListView from "./views/SourceMeta/SourceMetaListView";
import JobEditView from "./views/Jobs/JobEditView";
//import * as dotenv from 'dotenv';
import LogsListView from "./views/Logs/LogsListView";
import LogsListDetailsView from "./views/Logs/LogsListViewDetails";
import AppConfig from "./config";
import ExtractionView from "./views/Schema/ExtractionView";
import RetrievalView from "./views/Schema/RetrievalView";
import ImportView from "./views/Schema/ImportView";
import SchemaListView from "./views/Schema/SchemaListView";
import TableListView from "./views/Schema/TableListView";
import UserManagement from "./views/Management/UserManagement";
import UserService from "./services/UserService";
import TableCreateView from "./views/Schema/TableCreateView";
import WebhookListView from "./views/Webhook/WebhookListView";
import WebhookEditView from "./views/Webhook/WebhookEditView";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {AppConfig.AppVersion}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    purple: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    loggedInAvatar: {
        display: 'flex',
        textAlign: 'right',
        cursor: 'pointer'
    },
    username: {
        paddingRight: 5
    },
    settingsMenu: {
        fontSize: 12,
        paddingRight: 5,
        paddingTop: 4
    }
}));


const Main: FC = (): ReactElement => {
    // dotenv.config();
    const { user, isAuthenticated, isLoading, logout } = useAuth0();
    const { authUser, setLoggedUser } = useContext(UserContext);
    const { userIsAdmin } = UserService();
    const [isAdmin, setIsAdmin] = React.useState(false);

    const data = {
        columns: [
            ['data1', 30, 200, 100, 400, 150, 250],
            ['data2', 50, 20, 10, 40, 15, 25]
        ]
    };
    const mountNode = document.getElementById('react-c3js');
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const generateSidebar = () => {        
        if(isAdmin){
            return <List>{mainListItems}{adminListItems}</List>
        }else{
            return <List>{mainListItems}</List>
        }
    }

    useEffect(() => {
        if(user){
            userIsAdmin().then(() => {
                setIsAdmin(true);
            }).catch(() => {
                setIsAdmin(false);
            })
        }
    }, [user])

    if (!user) {
        return <SignIn />;
    }

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <img src={Logo} style={{ width: 205 }} />
                                </div>
                            </div>
                        </Typography>
                        <div className={classes.loggedInAvatar}>
                            <div>
                                <div className={classes.username}>{user.name}</div>
                                <div className={classes.settingsMenu}>Account Settings</div>
                            </div>
                            <Avatar className={classes.purple}>
                                <img src={user.picture} style={{ width: '100%' }} alt="" />
                            </Avatar>
                        </div>
                        <IconButton color="inherit">
                            {/* badgeContent={4} */}
                            <Badge color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <div>
                            <Button
                                onClick={() => logout({
                                    returnTo: window.location.origin
                                })}>
                                Logout
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    {generateSidebar()}
                    <Divider />
                    <List>{secondaryListItems}</List>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Switch>
                            <Route path="/" exact>
                                <HomeView />
                            </Route>
                            <Route path="/sources" exact>
                                <SourcesListView />
                            </Route>
                            <Route path="/source-connections" exact>
                                <SourceConnectionListView />
                            </Route>
                            <Route path={['/source-connections/edit/:id', '/source-connections/add']}>
                                <SourceConnectionEditView />
                            </Route>
                            <Route path="/source-connections/schemas/import/:id">
                                <ImportView />
                            </Route>
                            <Route path="/source-connections/schemas/extraction/:id">
                                <ExtractionView />
                            </Route>
                            <Route path="/source-connections/schemas/retrieval/:id">
                                <RetrievalView />
                            </Route>
                            <Route path="/source-connections/schemas/:id">
                                <SchemaListView />
                            </Route>
                            <Route path={['/schemas/:id/edit/:tableid', '/schemas/:id/add']}>
                                <TableCreateView />
                            </Route>
                            <Route path="/schemas/:id">
                                <TableListView />
                            </Route>
                            <Route  path={['/sources-meta/edit/:id', '/sources-meta/add']}>
                                <SourcesMetaEditView />
                            </Route>
                            <Route path="/sources-meta/setup/:id">
                                <SourcesMetaTableEditView />
                            </Route>
                            <Route path="/metadata-table-fields">
                                <SourcesMetaTableFieldsEditView />
                            </Route>
                            <Route path="/sources-meta">
                                <SourceMetaListView />
                            </Route>
                            <Route path="/source-objects">
                                <SourcesObjectsView />
                            </Route>
                            <Route path="/jobs" exact>
                                <JobsListView />
                            </Route>
                            <Route path="/logs" exact>
                                <LogsListView />
                            </Route>
                            <Route path={['/logs/:id']}>
                                <LogsListDetailsView />
                            </Route>
                            <Route path={['/jobs/edit/:id', '/jobs/add']}>
                                <JobEditView />
                            </Route>
                            <Route path="/webhooks" exact>
                                <WebhookListView />
                            </Route>
                            <Route path={['/webhooks/edit/:id', '/webhooks/add']}>
                                <WebhookEditView />
                            </Route>
                            <Route path="/users" exact>
                                <UserManagement />
                            </Route>
                        </Switch>
                        <Box pt={4}>
                            <Copyright />
                        </Box>
                    </Container>
                </main>
            </div>
        </>
    )
}

export default Main;