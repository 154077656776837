import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import TableCell from '@material-ui/core/TableCell';
import SourceConnectionService from "../../services/SourcesConnectionService";

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    }
  }));

interface PreviewProps {
    tableName?: string;
    connectionId: number;
    schemaId?: number;
    onClose: () => void;
    onError: () => void;
}

const Preview: FC<PreviewProps> = ({
    tableName,
    connectionId,
    schemaId,
    onClose,
    onError
}): ReactElement => {

    const { getPreview } = SourceConnectionService();
    
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState<string[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setTableData([])
    }

    useEffect(() => {
        if (tableName) {
            setLoading(true);
            getPreview({
                connection_id: connectionId,
                number_of_rows: 50,
                table_name: tableName,
                schema_id: schemaId
            }).then(data => {
                if (data && data.data.items.length) {
                    const newColumns = [];
                    for(const c in data.data.items[0]) {
                        newColumns.push(c);
                    }
                    //console.log(data.data.items);
                    //console.log(columns)
                    setColumns(newColumns);
                    setTableData(data.data.items);
                }
                setLoading(false);
            }).catch(error => {
                if (onError) {
                    onError()
                }
                setTableData([])
                setLoading(false)
            })
        }
    }, [tableName])

    return (
        <>
            <Dialog
                open={tableName != null}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="xl"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{`Preview table: ${tableName ? tableName.toUpperCase() : ''}`}</DialogTitle>
                <DialogContent>
                    {!loading && <table style={{minWidth: `100%`}} cellSpacing="0">
                        <thead>
                            {columns && columns.map(c => (
                                <th style={{border: '1px solid', padding: 5}}>{c}</th>
                            ))}
                        </thead>
                        <tbody>
                            {tableData && tableData.map(l => (
                                <tr>
                                    {columns && columns.map(c => (
                                        <td style={{border: '1px solid', padding: 5}}>{l[c]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                    {loading && (
                        <div>Loading...</div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary"> Close </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Preview;