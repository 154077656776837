import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import UserService from "../../services/UserService";
import { NewUser } from "../../models/UserModel";
import { stringify } from "querystring";


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    fullWidthField: {
        width: '100%'
    }
}));

interface CreateUserProps {
    show: boolean;
    setAlert: ({ title, message, severity }) => void;
    onCompletion: () => void;
    onClose: () => void;
}

const CreateUser: FC<CreateUserProps> = ({
    show = false,
    setAlert,
    onCompletion,
    onClose
}): ReactElement => {

    const { createNewUser } = UserService();
    const classes = useStyles();

    const [newUser, setNewUser] = useState<NewUser>();

    useEffect(() => {
        setNewUser({email: '', 
                    name: '', 
                    given_name: '', 
                    family_name: ''});
    }, [show])

    const addUser = () => {
        newUser.name = newUser.given_name + ' ' + newUser.family_name;
        createNewUser(newUser).then(res => {
            setAlert({title:'New User Added', message:'A verification email will be sent to ' + newUser.email ,severity:'success'});
        }).catch(res => {
            setAlert({title:'User Was Not Added', message: res.message, severity:'error'});
        }).finally(() => {
            onCompletion();
            handleClose();
        })
    }

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    const renderForm = () => {
        return (
            <form className={classes.root} noValidate autoComplete="off">
                <TextField 
                    className={classes.fullWidthField} 
                    id="outlined-basic" 
                    label='Email'
                    variant="outlined"
                    value={newUser.email}
                    onChange={(event) => {
                        setNewUser({
                                    email: event.target.value.trim(),
                                    name: newUser.name,
                                    given_name: newUser.given_name,
                                    family_name: newUser.family_name,
                                    });
                    }}
                    placeholder='Email' />
                <TextField 
                    className={classes.fullWidthField} 
                    id="outlined-basic" 
                    label='First Name'
                    variant="outlined"
                    value={newUser.given_name}
                    onChange={(event) => {
                        setNewUser({
                                    email: newUser.email,
                                    name: newUser.name,
                                    given_name: event.target.value.trim(),
                                    family_name: newUser.family_name,
                                    });
                    }}
                    placeholder='First Name' />
                <TextField 
                    className={classes.fullWidthField} 
                    id="outlined-basic" 
                    label='Last Name'
                    variant="outlined"
                    value={newUser.family_name}
                    onChange={(event) => {
                        setNewUser({
                                    email: newUser.email,
                                    name: newUser.name,
                                    given_name: newUser.given_name,
                                    family_name: event.target.value.trim(),
                                    });
                    }}
                    placeholder='Last Name' />
            </form>
        )
    }

    return (
        <Dialog
            open={show}
            keepMounted
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Add New User</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {newUser && renderForm()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={addUser} color="primary"> Add </Button>
                <Button onClick={handleClose} color="primary"> Cancel </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateUser;